import React from 'react'
import './src/styles/global.css'
import './src/styles/docs.css'
import "prismjs/themes/prism.css"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcU180kAAAAALHMLsFJ0FJD4go4SM5oCgDt7RMe">
            {element}
        </GoogleReCaptchaProvider>
    )
}